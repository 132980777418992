import { useState, useRef, forwardRef, useImperativeHandle, useEffect } from "react";
import { vector2 } from "utils/math";

import './CrosswordPuzzle.css'

type FieldProps = {
    className? : string,
    pos : vector2,
    letter: string,
    goalLetter?: string,
    
    showIsCorrect? : boolean,
    wordStartNo? : (number|string),
    reveal? : boolean,
    highlightField? : boolean,
    highlightWord? : boolean,
    
    onFilled?    : (v: vector2) => void,
    onEmptied?   : (v: vector2) => void,
    onSelected?  : (v: vector2) => void,
    onClick?     : (v: vector2) => void,
}

const Field = forwardRef<HTMLInputElement, FieldProps>((props : FieldProps, ref) => {
    const [letter, setLetter] = useState('');
    const innerRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => innerRef.current);

    if (!props.goalLetter) {
        return <div className={"field field-blocker"} />;
    }
    
    const isCorrect = props.goalLetter.toLowerCase() == letter.toLowerCase();
    const isEmpty = letter.trim() == "";
    const shouldReveal = true; //props.reveal;
    const isHighlightedWord = props.highlightWord;
    const isHighlightedField = props.highlightField;

    let className = "field"
    
    if (shouldReveal && !isEmpty) {
        if (isCorrect) className += " correct";
        else className += " wrong";
    }
    
    if (isHighlightedField) {
        className += " highlight-field";
    }
    else if (isHighlightedWord) {
        className += " highlight-word";
    }
    
    className += props.className ?? "";

    const showDecoration = props.wordStartNo && props.wordStartNo !== "#" && props.wordStartNo !== "0";

    return (
        <div className={className} id={`field-${props.pos.x}-${props.pos.y}`} onClick={() => props.onClick(props.pos)}>
            <div className={"letter"}>{props.letter}</div>
            {showDecoration && <div className={"word-start"}>{props.wordStartNo}</div>}
        </div>
    )
})

export default Field;