    export class vector2 {
        public x: number = 0;
        public y: number = 0;
        
        constructor(x : number, y : number) {
            this.x = x;
            this.y = y;
        }

        public clone = () : vector2 => {
            return new vector2(this.x, this.y);
        }

        public pass_by_value = () : vector2 => {
            return new vector2(this.x, this.y);
        }

        public static eq = (a : vector2, b: vector2) : boolean => {
            if (a == null && b == null) return true;
            if ((a == null && b != null) || (a != null && b == null)) return false;

            return a.x == b.x && a.y == b.y;
        }

        public static add = (a : vector2, b: vector2) : vector2 => {
            return new vector2(a.x + b.x, a.y + b.y);
        }

        public static sub = (a : vector2, b: vector2) : vector2 => {
            return new vector2(a.x - b.x, a.y - b.y);
        }

        public toString = () : string => {
            return `(${this.x},${this.y})`;
        }
    }

export function shuffle(arr : any[]) {
    for(let i = 0; i < arr.length; i++) {
        // Draw random element from elements that haven't been picked yet
        const j = Math.floor(i + Math.random() * (arr.length - i));

        // Swap the random element into current position
        const tmp = arr[i];
        arr[i] = arr[j];
        arr[j] = tmp;
    }
}