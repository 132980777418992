import { PropsWithChildren } from 'react'
import useFitText from "use-fit-text";

import './MobileClues.css'

type MobileCluesProps = {
    className? : string,
    onClick? : () => void,
    onNavigateLeft? : () => void,
    onNavigateRight? : () => void,
}

const MobileClues = (props : PropsWithChildren<MobileCluesProps>) => {
    const { fontSize, ref } = useFitText();

    return (
        <div className={`mobile-clues ${props.className}`}>
            <div className={"navi"} onClick={props.onNavigateLeft}>
                <span className={"material-symbols-outlined"} style={{lineHeight: "unset"}}>
                    chevron_left
                </span>
            </div>
            <div ref={ref} className={"clue"} onClick={props.onClick} style={{fontSize}}>
                {props.children}
            </div>
            <div className={"navi"} onClick={props.onNavigateRight}>
                <span className={"material-symbols-outlined"} style={{lineHeight: "unset"}}>
                    chevron_right
                </span>
            </div>
        </div>
    )
}

export default MobileClues;