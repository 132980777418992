import {ReactElement, PropsWithChildren, ReactNode} from 'react';
import "./Dialog.css"

export type DialogProps = {
    title? : ReactNode,
    onPrimary? : () => void,
    primary? : (ReactElement|string|null|undefined),
    children?: ReactNode | undefined,
}

export const Dialog = (props : DialogProps) => {
    return (
        <div className={"dialog-overlay"}>
            <div className={"dialog"}>
                {props.title && <div className={"dialog-title"}>{props.title}</div>}
                <div id={"dialog-content"} className={"dialog-content"} style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                    {props.children}
                </div>
                {props.primary &&
                    <div className={"button primary"} onClick={props.onPrimary}>
                        {props.primary}
                    </div>
                }
            </div>
        </div>
    )
}