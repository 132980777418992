import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from "react";
import { vector2 } from "utils/math";
import Field from "./Field";
import MobileClues from "./MobileClues";
import DesktopClues from "./DesktopClues";
import Keyboard from "react-simple-keyboard";
import { ipuzPuzzle } from '../../backend/puzzle';
import { observer } from "mobx-react-lite"

import './CrosswordPuzzle.css';
import "react-simple-keyboard/build/css/index.css";


import * as Croxx from './Croxx'
import {useStore} from "hooks/useStore";


type CrosswordPuzzleProps = {
    ipuzPuzzle   : ipuzPuzzle,
    inputEnabled : boolean,
    onSolved?    : () => void,
    onSkip?      : () => void,
}

const CrosswordPuzzle = observer((props : CrosswordPuzzleProps) => {
//    const [selectedCell, setSelectedCell] = useState(new vector2(0,0));
    const [selectedDir, setSelectedDir] = useState("h" as ("v"|"h"));
    const fields = useRef({});
    const keyboard = useRef();
    const [croxxStore] = useState(new Croxx.CroxxStore());

    useEffect(() => {
        if (!croxxStore) return;
        croxxStore.setPuzzle(props.ipuzPuzzle);
    }, [props.ipuzPuzzle])
    
    useEffect(() => {
        const handler = (e: KeyboardEvent) => {
            handleInput(e);
        };

        window.addEventListener('keydown', handler, false);
        return () => window.removeEventListener('keydown', handler, false);
    }, [croxxStore.letters, croxxStore.selectedCell, selectedDir]);

    useEffect(() => {
        if (croxxStore.solved) props.onSolved?.();
        if (props.ipuzPuzzle && !props.ipuzPuzzle.isOpenBox(croxxStore.selectedCell.x, croxxStore.selectedCell.y)) {
            const cell = props.ipuzPuzzle.getWordStart(1, "h");
            croxxStore.setSelectedCell(new vector2(cell.x,cell.y));
            setSelectedDir((o) => "h");
        }
    }, [croxxStore.solved, croxxStore.letters])

    const setFieldLetter = (v : vector2, str : string) => {
        croxxStore.setLetter(v, str);
    }

    const getFieldLetter = (v : vector2) => {
        return croxxStore.getLetter(v);
    }

    const setFieldValue = (v : vector2, str : string) => {
        if (str.length != 1) return;
        if (!Croxx.isAlphaNumericChar(str)) return;

        setFieldLetter(v, str);
        advanceCursor(croxxStore.selectedCell);
    }

    const deleteFieldValue = (v : vector2) => {
        if (getFieldLetter(v)) {
            setFieldLetter(v, "");
            return;
        }

        const newCell = advanceCursor(v, "back")
        setFieldLetter(newCell, "");
    }

    const advanceCursor = (v : vector2, dir : "forward" | "back" = "forward") : vector2 => {
        const isWordEnd = props.ipuzPuzzle?.isWordEnd(croxxStore.selectedCell.x, croxxStore.selectedCell.y, selectedDir);
        if (isWordEnd && dir === "forward") {
            moveToNextWord();
            return;
        }

        const isWordStart = props.ipuzPuzzle?.isWordStart(croxxStore.selectedCell.x, croxxStore.selectedCell.y, selectedDir);
        if (isWordStart && dir === "back") {
            moveToPreviousWord();
            return;
        }

        let velocity = new vector2(0,0);
        if (selectedDir == "h") {
            velocity.x += (dir == "forward") ? 1 : -1;
        }
        else if (selectedDir == "v") {
            velocity.y += (dir == "forward") ? 1 : -1;
        }

        let targetCell = croxxStore.selectedCell.pass_by_value();
        targetCell.x += velocity.x;
        targetCell.y += velocity.y;

        return moveCursorBy(velocity);
    }

    const handleInput = (e : KeyboardEvent) => {
        if (!props.inputEnabled) return;

        if (Croxx.isAlphaNumericChar(e.key)) {
            setFieldValue(croxxStore.selectedCell, e.key.toUpperCase());
            return;
        }

        if (e.code == "Backspace") {
            deleteFieldValue(croxxStore.selectedCell);
            return;
        }
        if (e.code == "Enter") {
            moveToNextWord();
            return;
        }
        if (e.code == "F8") {
            props.onSolved?.()
            return;
        }
        if (e.code == "Space") {
            advanceCursor(croxxStore.selectedCell);
            return;
        }

        let velocity = new vector2(0,0);
        if (e.code == "ArrowUp") velocity.y -= 1;
        if (e.code == "ArrowDown") velocity.y += 1;
        if (e.code == "ArrowLeft") velocity.x -= 1;
        if (e.code == "ArrowRight") velocity.x += 1;

        moveCursorBy(velocity);
    }

    const moveCursorBy = (v : vector2) : vector2 => {
        if (v.x != 0 && selectedDir == "v") {
            setSelectedDir(o => "h");
            return;
        }

        if (v.y != 0 && selectedDir == "h") {
            setSelectedDir(o => "v");
            return;
        }

        const target = vector2.add(croxxStore.selectedCell, v);
        const isBlackBox = props.ipuzPuzzle.isBlackBox(target.x, target.y);
        if (isBlackBox) return;

        const isInsidePuzzle = props.ipuzPuzzle.isInsidePuzzle(target.x, target.y);
        if (!isInsidePuzzle) return;

        croxxStore.setSelectedCell(target);
        return target;
    }

    const moveCursorTo = (v : vector2) => {
        croxxStore.setSelectedCell(v.pass_by_value());
    }

    const moveToNextWord = () => {
        if (!props.ipuzPuzzle) return;

        const wordNum = props.ipuzPuzzle.getWordNum(croxxStore.selectedCell.x, croxxStore.selectedCell.y, selectedDir);
        const nextWord = props.ipuzPuzzle.getNextWord(wordNum, selectedDir);
        const nextWordStart = props.ipuzPuzzle.getWordStart(nextWord.num, nextWord.dir);

        if (!nextWordStart) return;
        moveCursorTo(new vector2(nextWordStart.x, nextWordStart.y));
        setSelectedDir(() => nextWord.dir);
    }

    const moveToPreviousWord = () => {
        if (!props.ipuzPuzzle) return;

        const wordNum = props.ipuzPuzzle.getWordNum(croxxStore.selectedCell.x, croxxStore.selectedCell.y, selectedDir);
        const prevWord = props.ipuzPuzzle.getPreviousWord(wordNum, selectedDir);
        const prevWordEnd = props.ipuzPuzzle.getWordEnd(prevWord.num, prevWord.dir);

        if (!prevWordEnd) return;
        moveCursorTo(new vector2(prevWordEnd.x, prevWordEnd.y));
        setSelectedDir(() => prevWord.dir);
    }

    const onFieldClicked = (v : vector2) => {
        if (vector2.eq(croxxStore.selectedCell, v)) {
            setSelectedDir(selectedDir == "v" ? "h" : "v");
        }

        croxxStore.setSelectedCell(v.pass_by_value());
    }

    const addField = (f, pos : vector2) => {
        fields.current[pos.toString()] = f;
    }

    const onSelectField = (v : vector2) => {
        croxxStore.setSelectedCell(v.pass_by_value());
    }

    const advanceWord = (dir : "forward" | "back" = "forward") => {
        if (!props.ipuzPuzzle) return;
        const curWordNum = props.ipuzPuzzle.getWordNum(croxxStore.selectedCell.x,croxxStore.selectedCell.y, selectedDir);

        if (dir === "forward") {
            const nextWord = props.ipuzPuzzle.getNextWord(curWordNum, selectedDir);
            const nextWordStart = props.ipuzPuzzle.getWordStart(nextWord.num, nextWord.dir);
            moveCursorTo(new vector2(nextWordStart.x, nextWordStart.y));
            return;
        }

        if (dir === "back") {
            const prevWord = props.ipuzPuzzle.getPreviousWord(curWordNum, selectedDir);
            const prevWordStart = props.ipuzPuzzle.getWordStart(prevWord.num, prevWord.dir);
            moveCursorTo(new vector2(prevWordStart.x, prevWordStart.y));
            return;
        }
    }

    const getMobileClue_v2 = () => {
        if (!props.ipuzPuzzle) return null;
        return props.ipuzPuzzle.getClue(croxxStore.selectedCell.x, croxxStore.selectedCell.y, selectedDir);
    }

    const isHighlighted_v2 = (v : vector2) => {
        if (selectedDir === "h")
            return croxxStore.selectedCell.y == v.y;

        if (selectedDir === "v")
            return croxxStore.selectedCell.x == v.x;

        return false;
    }

    const render_v2 = () => {
        if (!props.ipuzPuzzle) return <><p>Loading</p></>;

        const dimensions = props.ipuzPuzzle.raw.dimensions;
        let rows = [];
        for (let y = 0; y < dimensions.height; y++) {

            let fields = []
            for (let x = 0; x < dimensions.width; x++) {
                let cell = new vector2(x,y);

                let goalLetter = props.ipuzPuzzle.solution[y][x];
                if (goalLetter === "#") {
                    goalLetter = "";
                }

                let no = props.ipuzPuzzle.decoration[y][x]
                if (typeof no !== "number") {
                    no = undefined;
                }


                const maxX = dimensions.width-1;
                const maxY = dimensions.height-1;
                let clazz = "";
                if (cell.x == 0) clazz += " no-left-border";
                if (cell.x == maxX) clazz += " no-right-border";
                if (cell.y == 0) clazz += " no-top-border";
                if (cell.y == maxY) clazz += " no-bottom-border";
                if (cell.x == 0    && cell.y == 0) clazz += " top-left-border";
                if (cell.x == maxX && cell.y == 0) clazz += " top-right-border";
                if (cell.x == 0    && cell.y == maxY) clazz += " bottom-left-border";
                if (cell.x == maxX && cell.y == maxY) clazz += " bottom-right-border";

                fields.push(<Field
                    className={clazz}
                    letter={croxxStore.getLetter(cell)}
                    pos={cell}
                    highlightField={vector2.eq(croxxStore.selectedCell, cell)}
                    highlightWord={isHighlighted_v2(cell)}
                    wordStartNo={no}
                    goalLetter={goalLetter}
                    onSelected={() => onSelectField(cell)}
                    onClick={onFieldClicked}
                    ref={(e) => addField(e, cell)}/>);
            }

            rows.push(<div className={"row"} id={`row-${y}`}>{fields}</div>);
        }

        return rows;
    }

    const layout = {
        'cross': [
            'Q W E R T Y U I O P',
            'A S D F G H J K L',
            'Z X C V B N M {bksp}',
        ],
    }

    const width  = (props.ipuzPuzzle) ? props.ipuzPuzzle.raw.dimensions.width  : 5;
    const height = (props.ipuzPuzzle) ? props.ipuzPuzzle.raw.dimensions.height : 5;

    return (
        <div className={"crossword"}>
            <div className={"clues hide-on-mobile"} />
            <div className={"puzzle-wrapper"}>
                <div className={"puzzle elevation-2"} style={{"--fields-x": width, "--fields-y": height} as React.CSSProperties}>
                    {render_v2()}
                </div>
            </div>
            <div className={"clues"}>
                <div className={"hide-on-mobile button primary"} onClick={props.onSkip}>
                    Skip
                </div>
                <DesktopClues
                    className={"hide-on-mobile"}
                    cluesAcross={props.ipuzPuzzle?.raw.clues.Across}
                    cluesDown={props.ipuzPuzzle?.raw.clues.Down}
                    selectedWordNum={props.ipuzPuzzle?.getWordNum(croxxStore.selectedCell.x,croxxStore.selectedCell.y,selectedDir)}
                    selectedDir={selectedDir}
                />
                <MobileClues
                    className={"hide-on-desktop"}
                    onClick={() => setSelectedDir(selectedDir == "h" ? "v" : "h")}
                    onNavigateLeft={() => advanceWord("back")}
                    onNavigateRight={() => advanceWord("forward")}
                >
                    {getMobileClue_v2()}
                </MobileClues>
            </div>
            <div className={"onscreen-keyboard"}>
                <Keyboard
                    keyboardRef={r => (keyboard.current = r)}
                    layout={layout}
                    layoutName={"cross"}
                    mergeDisplay={true}
                    display={{'{bksp}': '<span class="material-symbols-outlined" style="line-height: unset; font-weight: 300;">backspace</span>',}}
                    onChange={(i,e) => {}}
                    onKeyPress={(b,e) => {
                        if (b === "{bksp}") {
                            deleteFieldValue(croxxStore.selectedCell);
                            return;
                        }
                        setFieldValue(croxxStore.selectedCell, b.toUpperCase())
                    }}
                />
            </div>
        </div>
    )
})

export default CrosswordPuzzle;