import { ipuzPuzzle } from '../../backend/puzzle';
import { vector2 } from "utils/math";
import { makeAutoObservable } from "mobx"
export function isAlphaNumericChar(str: string) {
    if (str.length != 1) return;
    let code: number, i: number, len: number;

    for (i = 0, len = str.length; i < len; i++) {
        code = str.charCodeAt(i);
        if (!(code > 47 && code < 58) && // numeric (0-9)
                !(code > 64 && code < 91) && // upper alpha (A-Z)
                !(code > 96 && code < 123)) { // lower alpha (a-z)
            return false;
        }
    }
    return true;
}

export class CroxxStore {
    letters = {}
    puzzle : ipuzPuzzle = null
    selectedCell = new vector2(0,0)
    
    constructor() {
        makeAutoObservable(this)
    }

    get solved() {
        if (!this.puzzle) return false;
        
        const dimensions = this.puzzle.raw.dimensions;
        for (let y = 0; y < dimensions.height; y++) {
            for (let x = 0; x < dimensions.width; x++) {
                let cell = new vector2(x,y);
                let letter = this.letters[cell.toString()];
                if (this.puzzle.isBlackBox(x,y)) continue;
                if (this.puzzle.solution[y][x] !== letter) return false;
            }
        }
        return true;
    }
        
    public setPuzzle(puzzle : ipuzPuzzle) {
        this.puzzle = puzzle;
    }
    
    public setSelectedCell(cell : vector2) {
        this.selectedCell = cell;
    }
    
    public setLetter(pos: vector2, letter: string) {
        this.letters[pos.toString()] = letter;
    }
    
    public getLetter(pos: vector2) {
        return this.letters[pos.toString()];
    }
}

