import React from "react"; 
import { Outlet } from "react-router-dom";
import { Dialog, DialogProvider } from "../components";

import './Layout.css'

const Layout = () => { 
  return (
    <div className="app">
      <div id="app-bar-portal"></div>
      <React.Suspense fallback={<p>Loading ...</p>}>
        <DialogProvider>
          <Outlet />
        </DialogProvider>
      </React.Suspense>
    </div> 
  ); 
}; 

export default Layout;