import {ipuzClue} from "../../backend/puzzle";
import {decode} from "html-entities";
import './DesktopClues.css'

type DesktopCluesProps = {
    className? : string,
    cluesAcross? : ipuzClue[]
    cluesDown? : ipuzClue[]
    selectedWordNum : number,
    selectedDir : ("v"|"h"),
}

const DesktopClues = (props : DesktopCluesProps) => {
    return (
        <div className={`desktop-clues ${props.className}`}>
            <div className={"clue"}>
                <p><b>ACROSS</b></p>
                {props.cluesAcross?.map(c => <p className={props.selectedWordNum == c.number && props.selectedDir == "h" && "highlight"}><b>{c.number}</b> {decode(c.clue)}</p>)}
                <p><b>DOWN</b></p>
                {props.cluesDown?.map(c => <p className={props.selectedWordNum == c.number && props.selectedDir == "v" && "highlight"}><b>{c.number}</b> {decode(c.clue)}</p>)}
            </div>
        </div>
    )
}

export default DesktopClues;