import React, { createContext, useState, useContext, useCallback, PropsWithChildren, ReactNode, useEffect } from 'react';
import { Dialog, DialogProps } from "./Dialog"
import { v4 as uuidv4 } from 'uuid';

type DCProps = {
    id:        string,
    component: React.ElementType,
}

interface DialogContextData {
    showDialog: () => void;
    dismissDialog: () => void;
}
const DialogContext = createContext<DialogContextData | undefined>(undefined);

interface DialogRendererContext {
    dialogStack: DialogProps[];
}
const DialogRendererContext = createContext<DialogRendererContext | undefined>(undefined);

export const DialogProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [stack, updateStack] = useState([] as DialogProps[]);

//    const addDialog = (dialog: DialogProps) : () => void => {
//        let id = uuidv4();
//
//        updateStack(o => {
//            const next = [...o]
//            dialog._id = id;
//            next.push(dialog);
//            return next;
//        });
//        return () => { dismissDialog(id); }
//    }
//
//    const dismissDialog = (id : string) => {
//        updateStack(o => {
//            let next = [...o]
//            next = next.filter(e => e._id !== id);
//            return next;
//        });
//    }
    
//    const showDialog = (dialog: DialogProps) => {
//        return addDialog(dialog);
//    };

    return (
        <DialogContext.Provider value={{ showDialog: () => {}, dismissDialog: () => {} }}>
            <DialogRendererContext.Provider value={{ dialogStack: stack }}>
                {children}
            </DialogRendererContext.Provider>
        </DialogContext.Provider>
    );
};

export const useDialog = (): DialogContextData => {
    const context = useContext(DialogContext);
    if (!context) {
        throw new Error('useDialog must be used within a DialogProvider');
    }
    return context;
};

export const useDialogStack = (): DialogRendererContext => {
    const context = useContext(DialogRendererContext);
    if (!context) {
        throw new Error('useDialogStack must be used within a DialogProvider');
    }
    return context;
};